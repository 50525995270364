import React from 'react'
import CatFacesArticle from '../CatfacesArticle'
import SecondaryHero from '../SecondaryHero'

export default function CatFaces() {
  return (
    <>
    <SecondaryHero bkgurl="../images/millkyfaces.jpg" hOne="I am watching you"></SecondaryHero>
      <CatFacesArticle></CatFacesArticle>    
    </>
  )
}

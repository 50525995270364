
import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import Toys from './components/pages/Toys';
import CatStuff from './components/pages/CatStuff';
import PrimePouch from './components/pages/PrimePouch';
import AboutMe from './components/pages/AboutMe';
import PlantList from './components/pages/PlantList';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CatFaces from './components/pages/CatFaces';

import Home from './components/pages/Home';
import Goon from './components/pages/Goon';
import Hannibal from './components/pages/Hannibal';
import Jenesaisquoi from './components/pages/Jenesaisquoi';
function App() {
  return (
   <>
   <Router>
   <Navbar/>
    <Routes>
     
     <Route path="/toys" element = {<Toys/>} />
     <Route path="/jenesaisquoi" element = {<Jenesaisquoi/>} />
     <Route path="/catstuff" element = {<CatStuff/>} />
     <Route path="/aboutme" element = {<AboutMe/>} />
     <Route path="/primepouch" element = {<PrimePouch/>} />
     <Route path="/goon" element = {<Goon/>} />
     <Route path="/hannibal" element = {<Hannibal/>} />
     <Route path="/" element={<Home/>} />
     <Route path="/plantList" element = {<PlantList/>} />
     <Route path='/catfaces' element = {<CatFaces/>} />
    </Routes>
   </Router>
   
   </>

     
    
  );
}

export default App;

import React from 'react'

import { Link } from 'react-router-dom';
import "./Footer.css"
function Footer () {
  return (
    <>
    <footer>
<div className='footer-container'>

<section className = 'footer-subscription'>

< p className='footer-subscription-heading'>

   just a cat, doing cat things....
</p>
<div className='footer-nav'>
<ul className='footerlinks'>

<li className ='footer-item'>
  <Link to='/toys' className='footer-links' >
    Toys
  </Link>
  </li>
  <li className ='footer-item'>
  <Link to='/primepouch' className='footer-links' >
   My Belly
  </Link>
  </li>
  <li className ='footer-item'>
  <Link to='/jenesaisquoi' lang='fr'  className='footer-links' >
  Je ne sais quoi
  </Link>
  </li>
  <li className ='footer-item'>
  <Link to='/aboutme'   className='footer-links' >
  My Human
  </Link>
  </li>
</ul>
</div>
<div className='footer-area'>

    </div>
</section>
<div className='footer-links'>
        <div className='footer-link-wrapper'>
         
        
        </div>
        <div className='footer-link-wrapper'>
        
         
        </div>
      </div>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <Link to='/' className='social-logo'>
              MILKY
              <div className='pawspace'>
            <i className="fa-solid fa-paw fa-rotate-45"></i>
            </div>
            </Link>
          </div>
          <small className='website-rights'>MILKY © 2024</small>
          <div className='social-icons'>
          
           
            <Link
              className='social-icon-link github'
              to='https://github.com/bobgrill007'
              target='_blank'
              aria-label='Git Hub'
            >
              <i className='fab fa-github' />
            </Link>
            
            <Link
              className='social-icon-link twitter'
              to='/'
              target='_blank'
              aria-label='LinkedIn'
            >
              <i className='fab fa-linkedin' />
            </Link>
          </div>
        </div>
      </section>
</div>
    </footer>
    </>
      
    
  )
}

export default Footer

import React from "react";
import'../../App.css';
import ChangeTitle from "../ChangeTitle";
import Footer from "../Footer";
import SecondaryHero from "../SecondaryHero";
import TextBlock from "../TextBlock";
export default function CatStuff() {

    ChangeTitle("CatStuff")
return(
<>

<SecondaryHero bkgurl = "../images/milkface.jpg" hOne="Cat Stuff" ></SecondaryHero>

<TextBlock image="../images/milksick.jpg" alttext="scik cat showing teefies" text ="let me show you my teefeis"leftorright='left'></TextBlock>
  

<Footer></Footer>
</>



)
}
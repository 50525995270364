import React from 'react'
import CardItem from './CardItem'
import "./Cards.css"
function Cards ()  {
  return (
    <div className='cards'>
      <h1> My best pals</h1>
<div className="cards__container">
  <div className="cards__wrapper">
 <ul className ="cards__items"> 
<CardItem
src="images/goon.jpg"
text = " Goon the preties cat in the world. part Cat and part rabbit"
label = "Goon Cabbit"
path ="/goon"
alt = "A cat anemed goon relaxes on the couch"
/>

<CardItem
src="images/hannibal.jpg"
text = " Hannibal the biggest dog in the world"
label = "King ding a ling"
path ="/hannibal"
alt = " Picture of a sibearan husky"
/>
</ul>
<h2> Articles</h2>
<ul className ="cards__items"> 
<CardItem
src="../images/milkbelly.jpg"
text = " learn about my Primordial Pouch"
label = "it's my belly"
path ="/primepouch"
alt = "milk belly has a The Primordial Pouch >"
/>
         <CardItem
              src='images/catgrass.jpg'
              text='learn about Plants for Cats'
              label='Yummy Time'
              path='/plantlist'
              alt='cat eating cat grass'
            />
            <CardItem
              src='images/cathairtoy.jpg'
              text='My favorite hair toy'
              label='play time'
              path='/toys'
              alt='cat playing with hair tie'
            />
            <CardItem
              src='images/catstare.jpg'
              text='I am watching you'
              label=' what are you doing'
              path='/catfaces'
              alt='cat looking directly in the camera'
            />
          </ul>
   </div>
      </div>
    </div>
  )
}

export default Cards

import React from 'react'
import Footer from '../Footer';
import "../../App.css";
import ChangeTitle from '../ChangeTitle';
import SecondaryHero from '../SecondaryHero';
import TextBlock from '../TextBlock';

export default  function Goon (){
    ChangeTitle("Goon")
  return  (
    <>
    

  <SecondaryHero bkgurl = "../images/goonarm.jpg" hOne="An Xray of goon's arms" ></SecondaryHero>
  <TextBlock image="../images/goon/gooncuteface.jpg" alttext="goon looning sweet" text ="Goon in all of his heavinly glory" leftorright='right'></TextBlock>
  <TextBlock image="../images/goon/1goonmilkyhug.jpg" alttext="goon and milky cuddling ina cat house" text ="Cat houses provide a secure and warm environment for cats, allowing them to relax and snuggle together. These structures offer a sense of safety and comfort, perfect for cats who enjoy curling up in enclosed spaces. " leftorright='left'></TextBlock>
  <TextBlock image="../images/goon/goonbabymilky.jpg" alttext="milky as a kitten laying with goon" text ="Goon is my older brother by two years. I am just a kitten in this picture." leftorright='right'></TextBlock>
  <TextBlock image="../images/goon/goonjuju.jpg" alttext="goon and juji the dog " text ="this is goons best pal juji." leftorright='left'></TextBlock>
  <TextBlock image="../images/goon/goonmilkyhug.jpg" alttext="goon snuggling with milky" text ="Sleeping in cat piles is the best way to sleep!!!!!" leftorright='right'></TextBlock>
     <Footer> </Footer>
    </>
  )
}


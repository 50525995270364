import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: 20px auto;
    font-family: 'Arial, sans-serif';
`;

const ProductImage = styled.img`
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
`;

const Title = styled.h2`
    font-size: 1.5em;
    margin: 10px 0;
    color: #333;
`;

const Description = styled.p`
    font-size: 1em;
    color: #555;
    text-align: center;
    margin: 10px 0;
`;

const BuyButton = styled.a`
    display: inline-block;
    padding: 10px 20px;
    margin-top: 15px;
    background-color: #ff4500;
    color: white;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s;

    &:hover {
        background-color: #e63900;
    }
`;

const Springs = () => {
    return (
        <Container>
            <ProductImage src="../images/springs.png" alt="Andiker Creative Cat Toy" />
            <Title>Pawsome Playtime: Andiker Interactive Cat Toy</Title>
            <Description>
                Hello, fellow felines! Ever feel the urge to pounce and play? This Andiker Creative Interactive Cat Toy is just the thing for you!
                With colorful balls to swat and chase, it's designed to keep you entertained for hours. Perfect for sharpening those hunting skills!
            </Description>
            <BuyButton href="https://www.amazon.com/Andiker-Creative-Interactive-Colorful-Swatting/dp/B0839FGPFG" target="_blank" rel="noopener noreferrer">
                Buy Now on Amazon
            </BuyButton>
        </Container>
    );
};

export default Springs;

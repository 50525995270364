import React from 'react'
import '../App.css';
import './SecondaryHero.css';
/*import '../App.css';
const bkgurl="('../images/milkyeyes.jpg')";
const myStyle = {
  
  backgroundImage: `url(${bkgurl})`,
  height: "100vh",
  marginTop: "0px",
  fontSize: "50px",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
};

*/ 

     
function SecondaryHero ({bkgurl,hOne}){

   /* console.log(bkgurl);
    console.log(hOne);
 
     bkgurl="('../images/milkyeyes.jpg')";*/
   
    const myStyle = {
      
      backgroundImage: `url(${bkgurl})`,
      /*height: "100vh",*/
      height:"400px",
      marginTop: "0px",
      
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      color:" #fff",
      fontSize: "30px",
      
    };



    return (
        <>
     
      
 <div  className ="headerH1"style={myStyle}>
                <h1> {hOne}</h1>
            </div>
            
      </>
    )
  
}

export default SecondaryHero

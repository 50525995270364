import React from 'react'
import PrimordialPouchArticle from '../PrimordialPouchArticle'
import SecondaryHero from '../SecondaryHero'
import ChangeTitle from '../ChangeTitle'
import Footer from '../Footer'


function PrimePouch() {
  ChangeTitle("Primordial Pouch")
  return (
    
    <>
    <SecondaryHero bkgurl='../images/milkbelly.jpg' hOne="My Primordial Pouch"></SecondaryHero>
      <PrimordialPouchArticle></PrimordialPouchArticle>
      <Footer></Footer>
    </>
  )
}

export default PrimePouch

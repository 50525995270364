import React from "react";
import '../../App.css';
import './toys.css';
import ChangeTitle from "../ChangeTitle";
import Footer from "../Footer";
import SecondaryHero from "../SecondaryHero";
import TextBlock from "../TextBlock";
import ScratchPad from "../ScratchPad";
import Springs from "../Springs";
export default function Toys(){
ChangeTitle("Toys")
/*const bkgurl="../images/milkyeyes.jpg')"
const myStyle = {
  
  backgroundImage: `url(${bkgurl})`,
  height: "100vh",
  marginTop: "0px",
  fontSize: "50px",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
}; */
return(
   <>  <SecondaryHero bkgurl = "../images/milkyheader.jpg" hOne="Toys" ></SecondaryHero>
 
<TextBlock image="../images/goonsprings.jpg" alttext="goon ther cat playing eith toys" text ="my best pall goon has all the toys" leftorright='left' ></TextBlock>
 
 <div className="amazoncontainer">
 <ScratchPad></ScratchPad>
 <Springs></Springs>
 </div>
  <Footer></Footer>  
 
</>
)
}
import React from 'react'
import Footer from '../Footer'
import "../../App.css"
import ChangeTitle from '../ChangeTitle'


export default  function Hannibal (){
    ChangeTitle("hannibal")
  return  (
    <>
      <h1>Hannibal</h1>
     <Footer> </Footer>
    </>
  )
}


import React from 'react'
import "../components/textblock.css"
function TextBlock  ({image, text, alttext,leftorright})  {
 console.log(leftorright)
 var lr = leftorright.toString();
 if(lr === 'undefined'){
leftorright = 'right'
console.log('no value for leftorright')
 } 
 switch(leftorright) {
case 'left':  

  return (
   <>
   < div className='box-container'>
   <div className='textblock'>
      {text}
    </div>
    <div className ='imgcontainer'>
<img src= {image} alt= {alttext} />
    
    </div>
</div>
</>
  )

case 'right':
  return (
    <>
    < div className="box-container">
    <div className ='imgcontainerleft'>
 <img src= {image} alt= {alttext} />
     
     </div>
   
    <div className='textblock'>
       {text}
     </div>
 
 </div>
 </>
   )

  }
}
export default TextBlock

import React from 'react'
import PlantListArticle from '../PlantListArticle'
import SecondaryHero from '../SecondaryHero'

function PlantList() {
  return (
    <>
    <SecondaryHero bkgurl="../images/catgrass.jpg" hOne="Plants for Cats"></SecondaryHero>
      <PlantListArticle></PlantListArticle>
    </>
  )
}

export default PlantList

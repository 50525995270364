import React from 'react'
import ChangeTitle from '../ChangeTitle'
import '../../App.css';
import Footer from '../Footer';
import SecondaryHero from '../SecondaryHero';
import TextBlock from '../TextBlock';
import MyHuman from '../MyHuman';

export default function AboutMe() {
  
  
    ChangeTitle("My Human")
    return (
    <>

<SecondaryHero bkgurl = "../images/milkface.jpg" hOne="My Human" ></SecondaryHero>
  <MyHuman></MyHuman>
   
<Footer></Footer>
    </>
     

    
    
  )
}



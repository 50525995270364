import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import './Navbar.css';
function Navbar() {

  const handleKeypress = (event) => {
    if (event.keyCode === 13 ){

      handleClick();
    }
  }
    const[click, setClick] = useState(false);
    const closeMobileMenu = () => setClick(false);
    const handleClick = () => setClick(!click);
/*     const [button, setButton] = useState(true);
    const showButton = () => {
      if(window.innerWidth<= 960){
        setButton(false);
      }else {
        setButton(true);
      }
      };

      useEffect(() => {
        showButton();
      }, []);
    
      window.addEventListener('resize', showButton);
     */
  return (
    <>
      <nav className='navbar'>
<div className='navbar-container'>
<Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
            MILKY
            <div className='pawspace'>
            <i className="fa-solid fa-paw fa-rotate-45"></i>
            </div>
          </Link>
    <div role = 'button' aria-label='menu' tabIndex = '0'className='menu-icon' onClick={handleClick} onKeyUp={handleKeypress}>

      <i className={ click ?  'fas fa-times' : 'fas fa-bars'} />
       
    </div>
<ul className={ click ? 'nav-menu active' : 'nav-menu'}>

<li className ='nav-item'>
  <Link to='/toys' className='nav-links' onClick={closeMobileMenu}>
    Toys
  </Link>
  </li>
  <li className ='nav-item'>
  <Link to='/primepouch' className='nav-links' onClick={closeMobileMenu}>
   My Belly
  </Link>
  </li>
  <li className ='nav-item'>
  <Link to='/Jenesaisquoi' lang='fr'  className='nav-links' onClick={closeMobileMenu}>
  Je Ne Sais Quoi
  </Link>
  </li>
  <li className ='nav-item'>
  <Link to='/aboutme'   className='nav-links' onClick={closeMobileMenu}>
  My Human
  </Link>
  </li>
  <li className ='nav-item hidehomelink' >
  <Link to='/'   className='nav-links' onClick={closeMobileMenu}>
  Home
  </Link>
  </li>
</ul>


</div>
      </nav>
    </>
  )

}
export default Navbar

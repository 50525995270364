import React from 'react';
import './Article.css';
const expressions = [
  {
    name: 'Slow Blink',
    meaning: 'Affection and Trust. When your cat looks at you and slowly blinks, it\'s like a feline kiss.',
    action: 'Return the slow blink to show your cat you feel the same way. This helps reinforce the bond between you.'
  },
  {
    name: 'Ears Forward',
    meaning: 'Curiosity and Interest. Forward-facing ears indicate that your cat is attentive and curious about what\'s happening around them.',
    action: 'Engage with your cat by talking to them or offering a toy. They’re in the mood to explore and interact.'
  },
  {
    name: 'Purring with a Relaxed Face',
    meaning: 'Contentment and Happiness. A purring cat with relaxed features is a sign of a happy and comfortable feline.',
    action: 'Enjoy the moment! Pet them gently and provide affection to maintain their contentment.'
  },
  {
    name: 'Ears Back and Flat',
    meaning: 'Fear or Aggression. When a cat\'s ears are pinned back, they’re either scared or ready to defend themselves.',
    action: 'Give your cat space and avoid making sudden movements. Approach them calmly once they’ve relaxed.'
  },
  {
    name: 'Dilated Pupils',
    meaning: 'Excitement, Fear, or Aggression. Large pupils can indicate a range of emotions from excitement to fear.',
    action: 'Consider the context. If they’re playing, it’s excitement. If they’re hiding or hissing, they’re scared or aggressive.'
  },
  {
    name: 'Tail Up and Quivering',
    meaning: 'Excitement and Joy. A tail held high and quivering is a sign of a very happy cat, often seen when they greet their favorite person.',
    action: 'Respond with affection and petting. Your cat is showing they’re happy to see you.'
  },
  {
    name: 'Hissing or Growling',
    meaning: 'Warning and Discomfort. Hissing or growling is a clear sign that your cat is feeling threatened or annoyed.',
    action: 'Back off and give them space. Try to identify what’s causing their distress and remove it if possible.'
  },
  {
    name: 'Flattened Whiskers',
    meaning: 'Anxiety or Fear. Whiskers held flat against the face often indicate that a cat is scared or stressed.',
    action: 'Create a calm environment and avoid handling them until they’ve calmed down.'
  }
];

const CatFacesArticle = () => (
  <div className='article-container'>
    <h2>Understanding Cat Facial Expressions and Communication</h2>
    <ul>
      {expressions.map((expression, index) => (
        <li key={index}>
          <h3>{expression.name}</h3>
          <p><strong>Meaning:</strong> {expression.meaning}</p>
          <p><strong>What to Do:</strong> {expression.action}</p>
        </li>
      ))}
    </ul>
  </div>
);

export default CatFacesArticle;

import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin: 20px auto;
    font-family: 'Arial, sans-serif';
`;

const ProductImage = styled.img`
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
`;

const Title = styled.h2`
    font-size: 1.5em;
    margin: 10px 0;
    color: #333;
`;

const Description = styled.p`
    font-size: 1em;
    color: #555;
    text-align: center;
    margin: 10px 0;
`;

const BuyButton = styled.a`
    display: inline-block;
    padding: 10px 20px;
    margin-top: 15px;
    background-color: #ff4500;
    color: white;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s;

    &:hover {
        background-color: #e63900;
    }
`;

const ProductCard = () => {
    return (
        <Container>
            <ProductImage src="./images/ScratchPad.png" alt="Cat Scratcher" />
            <Title>Feline Favorite: The Ultimate Cat Scratcher</Title>
            <Description>
                Hey there, fellow felines! Looking for the purr-fect place to sharpen your claws and lounge around?
                Look no further! This scratcher is made from high-quality, recyclable cardboard and is completely
                reversible for twice the fun. Trust me, your claws will thank you!
            </Description>
            <BuyButton href="https://www.amazon.com/Scratcher-Cardboard-Reversible-Recyclable-Suitable/dp/B09BYZPG96/ref=sr_1_2_pp?dib=eyJ2IjoiMSJ9.2LwfSqVqtKh9dMzFkLJRFU88EtGIdeV8Z5tzmzO_RRhNiMP7Ci4-d6KvVIN9OzCxq5TpS9usXAL8AV3ceJetWRsZK6fzrwz0aX_cVtocZ7M5LTs3TH4gs09mdIzv56huauzhnI97Aud6hzIYux3S8IourT2QiSh0b3AngMwW46JE4KD4AI6KenC7Qxhe-CCrVYEferG2I0nfAG5idPiO_uMU0v1u-O633QDLXcPsc2im-B-Ry58NsX663FfUpOMF0pSsttY1I5ETW7dO353ZqOv56G1apYoKTtUoFH2cyhk.vxBhxlcBMx7xRBsHiW5MysXYwKOe-YMNKSJCeylFSDI&dib_tag=se&keywords=cat%2Bscratcher&qid=1718298939&sr=8-2&th=1" target="_blank" rel="noopener noreferrer">
                Buy Now on Amazon
            </BuyButton>
        </Container>
    );
};

export default ProductCard;

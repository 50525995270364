import React from 'react';
import './Article.css'
const MyHuman = () => {
    return (
        <div class = 'article-container'style={{  padding: '20px', maxWidth: '800px', margin: 'auto' }}>
            <h1>Meet My Human: Robert</h1>
            <p>Hi there, I'm Milky Joe, and I'd like to tell you all about my human, Robert Grill. He's quite an extraordinary fellow, always busy with something called "accessibility" – whatever that means. Here's a little overview of what he does when he's not serving me delicious tuna treats.</p>

            <h2>Current Role</h2>
            <p>
                <strong>Senior Accessibility Manager</strong><br />
                My human is currently the Sr. Manager of Accessibility Engineering. He leads two teams of accessibility engineers and designers. He makes sure that everything they create can be used by everyone, which I think means all the humans can enjoy it as much as I enjoy a sunny spot on the windowsill.
            </p>
            
            <h2>Past Adventures</h2>
            <p>
                <strong>VP Accessibility Lead - Card Line of Business</strong><br />
                From 2016 to 2022, my human worked at J.P. Morgan Chase in Wilmington, DE. He started a new accessibility team for their credit card line. He was like a superhero, making sure their digital properties were accessible and compliant.
            </p>
            <br/>
            <p>
                <strong>VP Accessibility Lead - Digital Line of Business</strong><br />
                Before that, from 2011 to 2016, he was the accessibility lead for the digital line at J.P. Morgan Chase. He worked on something called "Chase 3.0" and helped integrate accessibility into their processes.
            </p>
            <br/>
            <p>
                <strong>VP Product Manager Sr. Developer</strong><br />
                Between 2009 and 2011, he provided technical guidance and support for Chase.com. He even created a special calculator that could be used by everyone, which is pretty cool if you ask me.
            </p>

            <h2>Other Notable Roles</h2>
           
            <ul>
                <li><strong>Blueprint Media:</strong> My human ran his own company from 2006 to 2009, developing interactive sites and applications for various clients. It sounds like he was quite the boss!</li>
                <li><strong>FutureTech:</strong> He managed development processes and provided technical direction from 2004 to 2006.</li>
                <li><strong>Convention Architect / Developer:</strong> At Cadient Group in 2004-2005, he designed and developed cool stuff for conventions.</li>
                <li><strong>Tech Lead at Razorfish:</strong> He led technical teams at Razorfish in 2003-2004.</li>
                <li><strong>Sr. Software Engineer at Qwest Communications:</strong> My human was a senior software engineer from 2000 to 2003.</li>
                <li><strong>Web Developer at Devon Consulting:</strong> He developed web applications for Merck from 1999 to 2000.</li>
            </ul>
            
            <h2>Technical Prowess</h2>
            <p>
                My human is pretty smart with technology. He knows how to use tools like Adobe Experience Manager, Rally, Workfront, and Figma. He can speak in languages like JavaScript, HTML, and CSS – though I haven't heard him meow in any of those yet.
            </p>

            <h2>Education and Speaking</h2>
            <p>
                He studied Telecommunications with a minor in Speech and Public Relations at Kutztown University. He's also spoken at some important events like the CSUN Conference and the University of Delaware, sharing his knowledge about accessibility.
            </p>
            
            <p>So, that's my human in a nutshell. When he's not busy making the digital world a better place, he's usually giving me chin scratches or playing with my favorite feather toy. He's pretty great, if I do say so myself.</p>
        </div>
    );
};

export default MyHuman;

import React from 'react';
import '../App.css';
import { Button } from './Button';
import "./Button.css" 



import  './herosection.css';
function HeroSection() {
  return (
   <>  
   <div className='hero-container'>
      {/* <video src = '/videos/video-2.mp4' autoPlay loop muted/>*/}
<h1> The ledgend of Milky Joe </h1>
<p> Are you kitten me right MEOW???</p>
<div className='hero-btns'>
  <Button className='btns'
  buttonSize='btn--large'
  buttonStyle='btn--outline'
  buttonlink="aboutme"
>My Human </Button>
<Button className='btns'
  buttonSize='btn--large'
  buttonStyle='btn--primary'
  buttonlink ='toys'
>Cat toys</Button>
</div>
</div>
  </>
  )
}

export default HeroSection

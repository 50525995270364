
import React from 'react';
import'../../App.css';  
import HeroSection from "../HeroSection";
import ChangeTitle from "../ChangeTitle";
import Cards from '../Cards';
import Footer from '../Footer';
export default function Home() {  
    
    
    ChangeTitle("Milky Joe's world") 
    
    
    return (
    
    <>
    <HeroSection />
<Cards/>
<Footer />


</>

    )
    
    
  

}
import React from 'react';
import './Article.css';
const plants = {
  good: [
    {
      name: 'Cat Grass (Dactylis glomerata)',
      benefits: 'Safe and healthy, aids in digestion, helps with hairball control.',
      careTips: 'Easy to grow indoors. Keep the soil moist and place it in indirect sunlight.'
    },
    {
      name: 'Spider Plant (Chlorophytum comosum)',
      benefits: 'Non-toxic and visually appealing, cats enjoy batting at the long, arching leaves.',
      careTips: 'Requires bright, indirect light and well-draining soil. Water when the top inch of soil is dry.'
    },
    {
      name: 'Areca Palm (Dypsis lutescens)',
      benefits: 'Safe for cats and adds a tropical feel to your home. The fronds can provide playful interaction for your pet.',
      careTips: 'Prefers bright, indirect light and regular watering. Ensure the pot has good drainage.'
    },
    {
      name: 'Bamboo Palm (Chamaedorea seifrizii)',
      benefits: 'Safe for cats and helps purify the air.',
      careTips: 'Thrives in low to bright indirect light. Keep the soil evenly moist but not waterlogged.'
    }
  ],
  bad: [
    {
      name: 'Lilies (Lilium spp.)',
      risks: 'Extremely toxic. Even a small amount can cause severe kidney damage.',
      symptoms: 'Vomiting, lethargy, loss of appetite, and kidney failure.'
    },
    {
      name: 'Aloe Vera',
      risks: 'Contains saponins and anthraquinones, which can be harmful if ingested.',
      symptoms: 'Vomiting, diarrhea, lethargy, and tremors.'
    },
    {
      name: 'Philodendron',
      risks: 'Contains calcium oxalates, which can cause irritation and swelling in the mouth and throat.',
      symptoms: 'Drooling, oral pain, difficulty swallowing, and vomiting.'
    },
    {
      name: 'Sago Palm (Cycas revoluta)',
      risks: 'Highly toxic. All parts of the plant, especially the seeds, contain cycasin.',
      symptoms: 'Vomiting, diarrhea, liver failure, and death if untreated.'
    }
  ]
};

const PlantList = () => (
  <div className='article-container'>
    <h2>Good Plants for Cats</h2>
    <ul>
      {plants.good.map((plant, index) => (
        <li key={index}>
          <h3>{plant.name}</h3>
          <p><strong>Benefits:</strong> {plant.benefits}</p>
          <p><strong>Care Tips:</strong> {plant.careTips}</p>
        </li>
      ))}
    </ul>
    <h2>Bad Plants for Cats</h2>
    <ul>
      {plants.bad.map((plant, index) => (
        <li key={index}>
          <h3>{plant.name}</h3>
          <p><strong>Risks:</strong> {plant.risks}</p>
          <p><strong>Symptoms:</strong> {plant.symptoms}</p>
        </li>
      ))}
    </ul>
  </div>
);

export default PlantList;

import React from "react";
import '../../App.css';
import ChangeTitle from "../ChangeTitle";
import Footer from "../Footer";
import TextBlock from "../TextBlock";
import SecondaryHero from "../SecondaryHero";

export default function Jenesaisqui(){
ChangeTitle("Je ne sais quoi");
    return (
        
        <> 
  
  <SecondaryHero bkgurl = "../images/milkflower.jpg" hOne="Je ne sais quoi" ></SecondaryHero>
  <TextBlock image="../images/milkface.jpg" alttext="cat looking at you with cute face" text ="felt CUTE, might delete later" leftorright='right'></TextBlock>

  <Footer></Footer>
        </>
    )
}